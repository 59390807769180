<script>
import { OptionsComponent } from '@drapejs/core';
import { defineComponent } from 'vue';
import { sales } from '@distancify/drapejs-litium';
import { validateEmail } from '../../utils.ts';

export default defineComponent({
  extends: OptionsComponent,
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      message: '',
    };
  },
  computed: {
    title() {
      return this.block.fields.Title;
    },
    subtitle() {
      return this.block.fields.SubTitle;
    },
    placeholder() {
      return this.block.fields.Placeholder;
    },
    buttonText() {
      return this.block.fields.ButtonText;
    },
    consentMessage() {
      return this.block.fields.ConsentMessage;
    },
    invalidMailMessage() {
      return this.block.fields.InvalidMailMessage;
    },
    successfulSignupMessage() {
      return this.block.fields.SuccessfulSignupMessage;
    },
    unsuccessfulSignupMessage() {
      return this.block.fields.UnsuccessfulSignupMessage;
    },
  },
  methods: {
    async subscribe() {
      if (!this.email || !validateEmail(this.email.trim())) {
        this.message = this.invalidMailMessage;
        return;
      }

      const requestArgs = {
        url: `${this.$route.protocol}//${this.$route.host}${this.$route.pathname}`,
        email: this.email.trim(),
      };

      const response = await this.$invoke(
        sales.subscribeToNewsletter,
        requestArgs,
      );

      this.message = response.customerNumber
        ? this.successfulSignupMessage
        : this.unsuccessfulSignupMessage;
    },
    clearError() {
      this.message = '';
    },
  },
});
</script>

<template>
  <div class="_newsletter">
    <h1 v-if="title" class="_newsletter-title">{{ title }}</h1>
    <p v-if="subtitle" class="_newsletter-subtitle">{{ subtitle }}</p>

    <div class="_newsletter-form">
      <input
        type="text"
        :placeholder="placeholder"
        v-model="email"
        @focus="clearError"
        class="_newsletter-input"
        @keyup.enter="subscribe"
      />
      <a
        class="_newsletter-button"
        :title="buttonText"
        @click="subscribe"
      >
        <img src="../../static/icons/arrow.svg" />
      </a>
    </div>

    <div
      class="_newsletter-message"
      v-if="!message"
      v-html="consentMessage"
    ></div>

    <div
      class="_newsletter-message"
      v-if="message"
      v-html="message"
    ></div>
  </div>
</template>

<style>
._newsletter {
    text-align: center;
    padding: 10px;
}

._newsletter-title {
  font-family: var(--font-proxima-nova-bold);
  font-weight: 700;
  font-size: 27px;
  line-height: 27px;
  color: var(--color-text-standard);
  margin: 25px 0 0;
}

._newsletter-subtitle {
  font-family: var(--font-proxima-nova-light);
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-standard);
  margin: 10px auto 40px;
  max-width: 409px;
}

._newsletter-form {
  border-bottom: 1px solid #000000;
  padding-bottom: 10px;
  max-width: 409px;
  margin: auto;
  display: flex;
}

input[type=text]._newsletter-input {
  font-family: var(--font-proxima-nova-light);
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #000000;
  border: none;
  outline: none;
  appearance: none;
  width: 100%;
  padding: 0;
  height: 24px;
}

._newsletter-button {
  height: 24px;
  cursor: pointer;
}

._newsletter-message {
  font-family: var(--font-proxima-nova-light);
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-standard);
  margin: 10px auto 25px;
  text-align: left;
  max-width: 409px;
}

._newsletter-message a {
  color: var(--color-text-standard);
}

@media(--tabletAndDesktop) {
  ._newsletter-title {
    font-size: 40px;
    line-height: 40px;
  }
}

</style>
